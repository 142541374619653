import React, { Component } from "react";
import myConfig from "../../configs/config";
import Kpi_gadget from "../../components/gadgets/Kpi_gadget";
import Title from "../../components/Title";
import MapContainer from "../../components/Polygon_map";
import Button from "../../components/Button";
import axios from "axios";
import "../../../src/App.css";
import classnames from "classnames";
import MDSpinner from "react-md-spinner";
import {
  getErrorComponent,
  default_error_message,
  changeToBrowserTimezone,
  distance,
} from "../../helpers/utils";
import { withTranslation } from "react-i18next";
import SimpleModal from "./SimpleModal";
import AgriDateTime from "../../components/AgriDateTime";
import { getFormatedDate, getTime } from "../../helpers/utils";
import PdfContainer from "../../components/PdfContainer";
import Doc from "../../components/DocService";
import OperationReprocess from "./Reprocess";
import OperationReport from "./Report";
import {
  FaPen,
  FaTrashAlt,
  FaCheckCircle,
  FaFileAlt,
  FaSearch,
} from "react-icons/fa";
import SelectBox from "../../components/SelectBox";
import LineGraph from "./LineGraph";
import CardContent from "../../components/CardContent";

class OperationDetail extends Component {
  operationId = this.props.match.params.operation_id;
  farmId = this.props.match.params.farm_id;

  PROGRESS_DETAIL = `${myConfig.API_URL}/progress/${this.operationId}/?farm_id=${this.farmId}`;

  UPDATE_URL = `${myConfig.ABSOLUTE_URL}${this.farmId}/operation/${this.operationId}/change`;

  ALERT_URL = `${myConfig.ABSOLUTE_URL}alert/alert/${this.operationId}`;

  OPERATION_LIST_URL = `${myConfig.ABSOLUTE_URL}${this.farmId}/operation/`;

  FINISHED_URL = `${myConfig.API_URL}/operation/${this.operationId}/?farm_id=${this.farmId}`;

  DELETE_URL = `${myConfig.API_URL}/operation/${this.operationId}/?farm_id=${this.farmId}`;

  OPERATION_DATETIME = `${myConfig.API_URL}/operation/${this.operationId}/operation_datetime/?farm_id=${this.farmId}`;

  DATA_INIT_OPERATION = "";

  DATA_END_OPERATION = "";

  LANDPLOT_URL = `${myConfig.API_URL}/landplot/?farm_id=${this.farmId}`;

  constructor(props) {
    super(props);
    this.state = {
      farm_id: this.farmId,
      loading: false,
      operacao: "",
      operation: {
        status: {},
      },
      kpi_data: [],
      alert: {},
      alerts: [],
      map: {},
      show: false,
      errors: [],
      operation_id: this.operationId,
      selected: [],
      dt_ini: "",
      dt_end: "",
      dt_created: "",
      period_id: 0,
      periods: [
        { id: 0, name: "Hoje" },
        { id: 1, name: "Ontem" },
        { id: 2, name: "Semana atual" },
        { id: 3, name: "Semana Passada" },
        { id: 4, name: "Período completo" },
      ],
      isSelected: false,
      landplots: [],
    };

    this.handleFinished = this.handleFinished.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleChangeDateEnd = this.handleChangeDateEnd.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.choosePeriod = this.choosePeriod.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
  }

  getPeriod = () =>
    (new Date(this.state.dt_end).getTime() -
      new Date(this.state.dt_ini).getTime()) /
    (24 * 60 * 60 * 1000);

  createPdf = (html) => Doc.createPdf(html);

  handleMessage() {
    alert(
      "Está operação necessita estar finalizada para poder gerar o relatório."
    );
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleChangeDateEnd(e) {
    this.setState({
      dt_end: getFormatedDate(e),
    });
  }

  handleStartDateChange(e) {
    this.setState({
      dt_ini: getFormatedDate(e),
    });
  }

  handleStartTimeChange(e) {
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    var data = new Date(this.state.dt_ini);
    data = new Date(
      data.toLocaleDateString("en-US", options) + " " + e.target.value
    );
    this.setState({ dt_ini: getFormatedDate(data) });
  }

  handleEndTimeChange(e) {
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    var data = new Date(this.state.dt_end);
    data = new Date(
      data.toLocaleDateString("en-US", options) + " " + e.target.value
    );
    this.setState({ dt_end: getFormatedDate(data) });
  }

  handleFinished(event) {
    event.preventDefault();
    let form_data = new FormData();
    form_data.append("fineshed_at", new Date().toISOString());
    form_data.append("fineshed", true);

    if (!window.confirm("Deseja finalizar a operação ? ")) {
      return 0;
    } else {
      axios({
        method: "PATCH",
        url: this.FINISHED_URL,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
        data: form_data,
      }).then((res) => {
        alert("Operação finalizada com sucesso !");
      });
      window.location.href = `/${this.farmId}/operation/${this.operationId}/progress`;
    }
  }

  timer() {
    this.getDataValue();
  }

  async getDataValue() {
    let { data } = await axios.get(this.OPERATION_DATETIME);

    this.DATA_INIT_OPERATION = new Date(data.dt_ini);

    const PROGRESS_FILTER_URL =
      this.PROGRESS_DETAIL + "&dt_ini=" + data.init + "&dt_end=" + data.end;
    axios({
      baseURL: PROGRESS_FILTER_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          const operation = res.data;
          const widget = res.data.widget;
          let selected = "";

          let dt_end =
            data.end > operation.position_range.end
              ? data.end
              : operation.position_range.end;
          let dt_ini =
            data.init > operation.position_range.end
              ? data.init
              : operation.position_range.init;

          if (operation.fineshed_at !== null) {
            selected = [new Date(dt_ini), new Date(dt_end)];
          } else {
            let selectedDay = new Date();
            selected = [
              this.setInitTimeOfDay(selectedDay),
              this.setEndTimeOfDay(selectedDay),
            ];
          }

          this.setState({
            operation: {
              title: operation.ident,
              dt_ini: operation.dt_ini,
              finished_at: operation.fineshed_at,
              cost: operation.cost,
              hodometer: operation.hodometer,
              machine: operation.machine,
              implement: operation.impl,
              operator: operation.operator,
              area: operation.area,
              land_plot: operation.land_plot,
              status: {
                class: operation.status.class,
                progress: operation.status.progress,
              },
            },
            alert: {
              speed_alert_amount: operation.alert.speed_alert_amount,
              stopped_alert_amount: operation.alert.stopped_alert_amount,
              landplot_alert_amount: operation.alert.landplot_alert_amount,
            },
            kpi_data: [
              {
                label_header: this.props.t("Velocidade Atual") + ": ",
                header_value: operation.planned_speed.msg,
                header_class: operation.planned_speed.color,
                content_header: "",
                content_value: operation.planned_speed.value + " km/h ",
                content_class: operation.planned_speed.color,
                footer_header: this.props.t("Velocidade Planejada") + ": ",
                footer_value: operation.speed + " km/h",
              },
              {
                label_header: this.props.t("Área Trabalhada") + ": ",
                header_value:
                  widget.operation_type === "covering"
                    ? operation.worked_area.worked + "%"
                    : 0,
                content_header: "",
                content_value: operation.worked_area.area + " ha ",
                content_class: " yellow ",
                footer_header: this.props.t("Area Total") + ": ",
                footer_value:
                  widget.operation_type === "covering"
                    ? operation.area + " ha "
                    : 0,
              },
              {
                label_header: this.props.t("Previsão Termino") + ": ",
                header_value: "",
                content_header: "",
                content_value:
                  widget.operation_type === "covering"
                    ? operation.remain_time.remain
                    : "0h00min",
                content_class: " green ",
                footer_header: this.props.t("Início") + ": ",
                footer_value:
                  operation.init_point !== ""
                    ? operation.init_point
                    : "Aguardando ...",
              },
              {
                label_header: this.props.t("Tempo Total") + ": ",
                header_value: operation.total_time,
                content_header: "",
                content_value: operation.op_efficiency,
                content_class: " green ",
                footer_header: "",
                footer_value: "Eficiência : " + operation.op_efficiency_pc,
                // +
                // "| Ocioso : " +
                // operation.stopped_time,
              },
            ],
            map: {
              centroidLat: widget.centroidLat,
              centroidLng: widget.centroidLng,
              geojson: widget.geojson,
              lines: widget.lines,
              operation_type: widget.operation_type,
              stopped_points: widget.stopped_points,
            },
            selected: selected,
            dt_ini: selected[0],
            dt_end: selected[1],
            valid_dates: {
              init: new Date(data.dt_ini),
              end: new Date(dt_end),
            },
          });
          // console.log(`LOAD INIT ${selected[0]}`);
          this.DATA_END_OPERATION = selected[1];
          if (widget.operation_type !== "covering") {
            this.loadLandPlots();
          } else {
            this.setState({ loading: false });
          }
        } else {
          this.setState({
            errors: this.state.errors.concat(
              getErrorComponent(res.data.detail)
            ),
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: this.state.errors.concat(
            getErrorComponent(default_error_message)
          ),
          loading: false,
        });
      });
  }

  loadLandPlots() {
    axios({
      baseURL: this.LANDPLOT_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    })
      .then((res) => {
        let landplots = res.data;
        if (res.status === 200) {
          this.setState({
            landplots: landplots,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  }

  handleDelete(event) {
    event.preventDefault();

    if (!window.confirm("Deseja deletar a operação ? ")) {
      // console.log("não deletou");
      return 0;
    } else {
      axios({
        method: "DELETE",
        url: this.DELETE_URL,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      }).then((res) => {
        alert("Operação deletada com sucesso !");
        window.location.href = this.OPERATION_LIST_URL;
      });
    }
  }

  handleDateChange(e) {
    this.setState({
      dt_ini: getFormatedDate(e),
    });
  }

  componentWillMount() {
    this.setState({
      loading: true,
    });

    let ALERT_URL = `${myConfig.API_URL}/alert/?operation=${this.operationId}`;

    axios({
      baseURL: ALERT_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    }).then((res) => {
      this.setState({
        alerts: res.data,
      });
    });

    this.getDataValue();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setInitTimeOfDay(date) {
    let temp = new Date(date);
    temp.setHours(0);
    temp.setMinutes(0);
    temp.setSeconds(0);
    return temp;
  }

  setEndTimeOfDay(date) {
    let temp = new Date(date);
    temp.setHours(23);
    temp.setMinutes(59);
    temp.setSeconds(59);
    return temp;
  }

  getFastFilterData(range) {
    let data = {},
      today = new Date(),
      temp = "",
      week = "";

    switch (range) {
      case "today":
        data = {
          init: this.setInitTimeOfDay(today),
          end: this.setEndTimeOfDay(today),
        };
        break;
      case "yesterday":
        let yesterday = new Date(today.setDate(today.getDate() - 1));
        data = {
          init: this.setInitTimeOfDay(yesterday),
          end: this.setEndTimeOfDay(today),
        };
        break;
      case "week":
        temp = new Date(today);
        week = new Date(temp.setDate(temp.getDate() - temp.getDay()));
        data = {
          init: this.setInitTimeOfDay(week),
          end: this.setEndTimeOfDay(today),
        };
        break;
      case "last_week":
        const TOTAL_DAYS_WEEK = 8;
        temp = new Date(today);
        week = new Date(temp.setDate(temp.getDate() - temp.getDay()));
        let tmp_week = new Date(week);
        let last_week = new Date(
          tmp_week.setDate(tmp_week.getDate() - TOTAL_DAYS_WEEK)
        );
        let saturday_week = new Date(week.setDate(week.getDate() - 1));
        data = {
          init: this.setInitTimeOfDay(last_week),
          end: this.setEndTimeOfDay(saturday_week),
        };
        break;
      case "all_days":
        // console.log(`STATE ==> ${this.state.dt_ini} && ${this.state.dt_end}`);

        // console.log(
        //   `GLOBAL VARIABLES ==> ${this.DATA_INIT_OPERATION} && ${this.DATA_END_OPERATION}`
        // );

        // t  = selected[1]
        //  = selected[0]
        data = {
          init: this.DATA_INIT_OPERATION,
          end: this.DATA_END_OPERATION,
        };
        break;
      default:
        return "today";
    }
    // console.log(`ANTES DE SETAR O ESTADO ${data.init} && ${data.end}`);
    // console.log(
    //   `GLOBAL VARIABLES ==> ${this.DATA_INIT_OPERATION} && ${this.DATA_END_OPERATION}`
    // );
    this.setState({
      dt_ini: data.init,
      dt_end: data.end,
    });
  }

  async handleFilter(e, range) {
    e.preventDefault();
    let dt_ini = "";
    let dt_end = "";

    //correct timezone to UTC 0
    let tzoffset = new Date().getTimezoneOffset() * 60000;

    if (range) {
      await this.getFastFilterData(range);
    }

    dt_ini = new Date(new Date(this.state.dt_ini) - tzoffset)
      .toISOString()
      .split(".")[0];
    dt_end = new Date(new Date(this.state.dt_end) - tzoffset)
      .toISOString()
      .split(".")[0];

    // console.log(`***********`);
    // console.log(`handleFilter ==> ${dt_ini} && ${dt_end}`);
    // console.log(`***********`);

    //http://127.0.0.1:8000/api/v1/progress/progress_filter/
    const PROGRESS_FILTER_URL =
      this.PROGRESS_DETAIL + "&dt_ini=" + dt_ini + "&dt_end=" + dt_end;

    axios({
      baseURL: PROGRESS_FILTER_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("client-token")}`,
      },
    }).then(({ data }) => {
      let widget = data.widget;
      let operation = data;

      this.setState({
        operation: {
          title: operation.ident,
          dt_ini: operation.dt_ini,
          finished_at: operation.fineshed_at,
          cost: operation.cost,
          hodometer: operation.hodometer,
          machine: operation.machine,
          implement: operation.impl,
          operator: operation.operator,
          area: operation.area,
          land_plot: operation.land_plot,
          status: {
            class: operation.status.class,
            progress: operation.status.progress,
          },
        },
        alert: {
          speed_alert_amount: operation.alert.speed_alert_amount,
          stopped_alert_amount: operation.alert.stopped_alert_amount,
          landplot_alert_amount: operation.alert.landplot_alert_amount,
        },
        kpi_data: [
          {
            label_header: this.props.t("Velocidade Atual") + ": ",
            header_value: operation.planned_speed.msg,
            header_class: operation.planned_speed.color,
            content_header: "",
            content_value: operation.planned_speed.value + " km/h ",
            content_class: operation.planned_speed.color,
            footer_header: this.props.t("Velocidade Planejada") + ": ",
            footer_value: operation.speed + " km/h",
          },
          {
            label_header: this.props.t("Área Trabalhada") + ": ",
            header_value:
              widget.operation_type === "covering"
                ? operation.worked_area.worked + "%"
                : 0,
            content_header: "",
            content_value: operation.worked_area.area + " ha ",
            content_class: " yellow ",
            footer_header: this.props.t("Area Total") + ": ",
            footer_value:
              widget.operation_type === "covering"
                ? operation.area + " ha "
                : 0,
          },
          {
            label_header: this.props.t("Previsão Termino") + ": ",
            header_value: "",
            content_header: "",
            content_value:
              widget.operation_type === "covering"
                ? operation.remain_time.remain
                : "0h00min",
            content_class: " green ",
            footer_header: this.props.t("Início") + ": ",
            footer_value:
              operation.init_point !== ""
                ? operation.init_point
                : "Aguardando ...",
          },
          {
            label_header: this.props.t("Tempo Total") + ": ",
            header_value: operation.total_time,
            content_header: "",
            content_value: operation.op_efficiency,
            content_class: " green ",
            footer_header: "",
            footer_value: "Eficiência : " + operation.op_efficiency_pc,
            // +
            // "| Ocioso : " +
            // operation.stopped_time,
          },
        ],
        map: {
          centroidLat: widget.centroidLat,
          centroidLng: widget.centroidLng,
          geojson: widget.geojson,
          lines: widget.lines,
          operation_type: widget.operation_type,
          stopped_points: widget.stopped_points,
        },
        map_salt: Date.now(),
        loading: false,
        isSelected: false,
      });
    });
  }

  load_kpi_gadget() {
    let gadgets = [];
    for (var i = 0; i < this.state.kpi_data.length; i++) {
      gadgets.push(
        <Kpi_gadget
          key={i}
          label_header={this.state.kpi_data[i].label_header}
          header_value={this.state.kpi_data[i].header_value}
          content_header={this.state.kpi_data[i].content_header}
          content_value={this.state.kpi_data[i].content_value}
          content_class={this.state.kpi_data[i].content_class}
          footer_header={this.state.kpi_data[i].footer_header}
          footer_value={this.state.kpi_data[i].footer_value}
          header_class={this.state.kpi_data[i].header_class}
        />
      );
    }
    return gadgets;
  }

  hasReport() {
    try {
      const user = JSON.parse(localStorage.getItem("client"));
      return user.id === 30 || user.email.indexOf("agriconnected.com") !== -1;
    } catch (error) {}
    return false;
  }

  choosePeriod(e) {
    this.setState({
      period_id: e.target.value,
      isSelected: true,
    });
  }

  setPeriod(e) {
    let { isSelected } = this.state;
    let selectd_period = parseInt(this.state.period_id);

    if (isSelected === false) return this.handleFilter(e);

    if (selectd_period === 0) return this.handleFilter(e, "today");
    if (selectd_period === 1) return this.handleFilter(e, "yesterday");
    if (selectd_period === 2) return this.handleFilter(e, "week");
    if (selectd_period === 3) return this.handleFilter(e, "last_week");
    if (selectd_period === 4) return this.handleFilter(e, "all_days");
  }

  getOperationValues() {
    return [
      {
        title: "Área",
        value: this.state.operation.land_plot,
      },
      {
        title: "Máquina",
        value: this.state.operation.machine.ident,
      },
      {
        title: "Implemento",
        value: this.state.operation.implement,
      },
      {
        title: "Operador",
        value: this.state.operation.operator,
      },
      {
        title: "Hora de início",
        value: changeToBrowserTimezone(this.state.operation.dt_ini),
      },
      {
        title: "Hora de fim",
        value: changeToBrowserTimezone(this.state.operation.finished_at),
      },
      { title: "Odometro", value: this.state.operation.hodometer },
    ];
  }

  _calculateTotalDistance(data) {
    let totalDistance = 0;

    for (let i = 1; i < data.length; i++) {
      const { lat: lat1, lng: lon1 } = data[i - 1];
      const { lat: lat2, lng: lon2 } = data[i];

      totalDistance += distance(lat1, lon1, lat2, lon2);
    }

    return totalDistance;
  }

  _calculatePolygonArea(data) {
    const toRadians = (degree) => (degree * Math.PI) / 180;
    const R = 6371000; // Radius of Earth in meters

    let area = 0;
    const points = data.map((point) => ({
      lat: toRadians(point.lat),
      lon: toRadians(point.lng),
    }));

    for (let i = 0; i < points.length; i++) {
      const { lat: lat1, lon: lon1 } = points[i];
      const { lat: lat2, lon: lon2 } = points[(i + 1) % points.length];

      area += (lon2 - lon1) * (2 + Math.sin(lat1) + Math.sin(lat2));
    }

    area = Math.abs((area * R * R) / 2.0);

    // Convert area from square meters to hectares (1 hectare = 10,000 square meters)
    const areaInHectares = area / 10000;

    return areaInHectares;
  }

  _calculateSpeedTime(data) {
    let zeroSpeedTime = 0;
    let inMotionTime = 0;
    for (let i = 1; i < data.length; i++) {
      if (data[i - 1].velocidade === 0) {
        const previousTime = new Date(data[i - 1].time);
        const currentTime = new Date(data[i].time);
        const timeInterval = currentTime - previousTime;
        zeroSpeedTime += timeInterval;
      } else {
        const previousTime = new Date(data[i - 1].time);
        const currentTime = new Date(data[i].time);
        const timeInterval = currentTime - previousTime;
        inMotionTime += timeInterval;
      }
    }
    const zeroSpeedTimeInHours = zeroSpeedTime / (1000 * 60 * 60);
    const inMotionTimeInHours = inMotionTime / (1000 * 60 * 60);

    return {
      inertTime: zeroSpeedTimeInHours,
      inMotionTime: inMotionTimeInHours,
    };
  }

  getConsolidatedData = () => {
    const speedOverTime = this._getSpeedOverTime();
    const coords = this._getFlatLineArray();
    const { inertTime, inMotionTime } = this._calculateSpeedTime(speedOverTime);
    const totalDistance = this._calculateTotalDistance(coords);
    const totalArea = this._calculatePolygonArea(coords);
    return [
      {
        title: "Tempo imóvel",
        value: inertTime.toFixed(2) + " horas",
      },
      {
        title: "Tempo em movimento",
        value: inMotionTime.toFixed(2) + " horas",
      },
      {
        title: "Tempo total",
        value: (inertTime + inMotionTime).toFixed(2) + " horas",
      },
      {
        title: "Distância total",
        value: totalDistance.toFixed(2) + " km",
      },
      {
        title: "Área total",
        value: totalArea.toFixed(2) + " ha",
      },
    ];
  };

  _getFlatLineArray() {
    if (!this.state.map) return [];
    let { lines } = this.state.map;
    return lines.reduce((acc, { path }) => acc.concat(path), []);
  }

  _getSpeedOverTime() {
    let values = this._getFlatLineArray().map(({ time, speed }) => ({
      time: new Date(time),
      velocidade: Number(speed.split(" km/h")[0]),
    }));
    return values;
  }

  handleGraphData() {
    if (!this.state.map) return [];
    let { lines } = this.state.map;
    let values = lines.reduce((acc, { path }) => acc.concat(path), []);
    let res = values.map(({ time, speed }) => ({
      velocidade: Number(speed.split(" km/h")[0]),
      time: new Date(time).getTime(),
      timestamp: time,
    }));
    return res;
  }

  handleGraphClick = (point) => {
    const coords = this._getFlatLineArray().find(
      ({ time }) => time === point.timestamp
    );
    let google = window.google;
    let marker = new google.maps.Marker({
      position: coords,
      map: google.map,
    });
    google.map.setCenter(coords);
    google.map.setZoom(18);
    this.interval = setTimeout(() => marker.setMap(null), 5000);
  };

  get_content() {
    let relatorio = "";
    if (this.state.errors.length > 0) {
      return (
        <div
          style={{
            position: "absolute",
            marginTop: "25%",
            marginLeft: "25%",
            minHeight: "200px",
          }}
        >
          {this.state.errors.map((error) => (
            <div>{error}</div>
          ))}
        </div>
      );
    }

    // FAZENDA ELITE TRANSPORTES
    if (this.hasReport()) {
      if (this.state.operation.status.class === "finished") {
        relatorio = (
          <OperationReport
            farm_id={this.farmId}
            operation_id={this.operationId}
          />
        );
      } else {
        relatorio = (
          <div>
            <button
              type="button"
              className="btn btn-default pull-left"
              onClick={this.handleMessage}
            >
              <FaFileAlt />
              {this.props.t("Gerar Relatório")}
            </button>
          </div>
        );
      }
    }

    return (
      <div className="pt-40 operation-detail-container">
        <PdfContainer createPdf={this.createPdf}>
          <div className="x_content no-float">
            <div className="row tile_count">{this.load_kpi_gadget()}</div>
            <div className="text-center">
              <Title size="h1" title={this.state.operation.title}>
                <p className="inline-block">
                  <i
                    className={classnames(
                      this.state.operation.status.class,
                      "status-indicator"
                    )}
                  ></i>
                  {this.state.operation.status.progress}
                </p>
              </Title>
            </div>
            <div className="flex-box">
              <div className="op-search">
                <div className="x_panel auto mt-0">
                  <div
                    className="select-container"
                    aria-label="Filtros dinamicos"
                  >
                    <label
                      className="col-xs-12 no-padding"
                      htmlFor="select-container"
                    >
                      Período:
                    </label>
                    <SelectBox
                      strong="true"
                      data={this.state.periods}
                      selected_value={this.state.period_id}
                      value={"name"}
                      change={(e) => this.choosePeriod(e)}
                    />
                  </div>
                  <div className="col-filter col-xs-12 pull-left">
                    <AgriDateTime
                      time={getTime(this.state.dt_ini)}
                      align="horizontal"
                      timeLabel={this.props.t("Hora Inicio")}
                      label={this.props.t("Data inicial")}
                      datetimeformat="true"
                      selectedDate={this.state.dt_ini}
                      handleDateChange={this.handleStartDateChange}
                      validDates={this.state.valid_dates}
                      handleTimeChange={this.handleStartTimeChange}
                    />
                  </div>
                  <div className="col-filter col-xs-12 pull-left">
                    <AgriDateTime
                      time={getTime(this.state.dt_end)}
                      align="horizontal"
                      timeLabel={this.props.t("Hora Fim")}
                      label={this.props.t("Data Fim")}
                      validDates={this.state.valid_dates}
                      datetimeformat="true"
                      selectedDate={this.state.dt_end}
                      handleDateChange={this.handleChangeDateEnd}
                      handleTimeChange={this.handleEndTimeChange}
                    />
                  </div>
                  <Button
                    class="btn btn-info pull-left form-control w-40 btn-fast-filter mt-25 lh-25"
                    click={(e) => this.setPeriod(e)}
                  >
                    <FaSearch />
                  </Button>
                </div>
              </div>
            </div>
            <div className="map-container">
              <MapContainer
                map={this.state.map}
                map_salt={this.state.map_salt}
                operation_id={this.operationId}
                farm_id={this.farmId}
                equipment={this.state.operation.machine}
                landplots={this.state.landplots}
              />
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="x_content">
                  <ul className="list-unstyled">
                    <li className="pull-left col-md-3 text-xs-center col-xs-12">
                      <i className="line red-border"></i>
                      {this.props.t("Acima da velocidade")}
                    </li>
                    <li className="pull-left col-md-3 text-xs-center col-xs-12">
                      <i className="line blue-border"></i>
                      {this.props.t("Dentro da velocidade")}
                    </li>
                    <li className="pull-left col-md-3 text-xs-center col-xs-12">
                      <i className="line yellow-border"></i>
                      {this.props.t("Abaixo da velocidade")}
                    </li>
                    <li className="pull-left col-md-3 text-xs-center col-xs-12">
                      <i className="line white-border"></i>
                      {this.props.t("Fora do talhão")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <LineGraph
              data={this.handleGraphData()}
              period={this.getPeriod()}
              refValue={this.state.kpi_data.footer_value}
              onClick={this.handleGraphClick}
            />
            <div className="row">
              <CardContent data={this.getOperationValues()} />
              <CardContent data={this.getConsolidatedData()} />

              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="x_panel mt-10">
                  <p>
                    {this.props.t("Total de alertas de velocidade")}:{" "}
                    <b>{this.state.alert.speed_alert_amount}</b>
                  </p>
                  <p>
                    {this.props.t("Total de alertas de parada")}:{" "}
                    <b>{this.state.alert.stopped_alert_amount}</b>{" "}
                  </p>
                  <p>
                    {this.props.t("Total de alertas de e/s talhão")}:{" "}
                    <b>{this.state.alert.landplot_alert_amount}</b>{" "}
                  </p>

                  <SimpleModal alerts={this.state.alerts} />
                </div>
              </div>
            </div>
            {alert}
            <div className="row">
              <div className="text-center mt-10 col-xs-12">
                <Button
                  class="btn-default pull-left"
                  href={this.OPERATION_LIST_URL}
                >
                  {this.props.t("Voltar")}
                </Button>
                {/* <Button class="btn btn-default pull-left btn-reprocess" click={this.handleShowModal}><i className="fas fa-sync-alt mr-5"></i>{this.props.t('Reprocessar')}</Button> */}
                <OperationReprocess operation_id={this.operationId} />
                {relatorio}
                <div className="pull-right">
                  <Button class="btn-info" href={this.UPDATE_URL}>
                    <FaPen />
                    {this.props.t("Editar")}
                  </Button>
                  <Button class="btn-danger" click={this.handleDelete}>
                    <FaTrashAlt />
                    {this.props.t("Apagar")}
                  </Button>
                  <Button class="btn-primary" click={this.handleFinished}>
                    <FaCheckCircle />
                    {this.props.t("Finalizar")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </PdfContainer>
      </div>
    );
  }

  render() {
    let content = "";

    if (this.state.loading === false) {
      content = this.get_content();
    } else {
      content = (
        <div
          style={{
            position: "absolute",
            marginTop: "25%",
            marginLeft: "60%",
            minHeight: "200px",
          }}
        >
          <MDSpinner />
        </div>
      );
    }

    return <div>{content}</div>;
  }
}
export default withTranslation()(OperationDetail);
